import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Job App Template | Job App Design Template | Uizard"
    canonical="/templates/mobile-app-templates/job-mobile-app/"
    category="Mobile App Templates"
    url="/templates/tablet-templates/job-tablet-app/"
    metaDescription="Looking to design your own job app? Say hello to JOBSNAP, our job app design template. Sign up for Uizard Pro today and bring your idea to life."
    description="
    h2:Say hello to JOBSNAP, our job app design template for tablet
    <br/>
    Looking to design your own mobile job app? Our brand-new <a:https://uizard.io/templates/mobile-app-templates/>mobile app template</a>, JOBSNAP, is here to make your job all the easier. Our digital product design templates come with all the must-have UX features for your project, meaning you can design prototypes faster than ever before. Simply sign up for Uizard Pro, open the template, and share it with your team.
    <br/>
    h3:Job search app design just got a whole lot easier
    <br/>
    Whether you are a startup building out a brand-new vision, or you're an established brand looking to <a:https://uizard.io/mockups/>UI mockup</a> a new app design, Uizard is here to make the ideation and iteration stages of your project easier than ever before.
    <br/>
    h3:Customize our templates rapidly with your team
    <br/>
    Use JOBSNAP straight out of the box or customize it to suit your vision. Uizard's drag-and-drop editor is super easy and streamlined to use, meaning you can put your aesthetic spin on our premade templates with ease. Adding new screens and new user journeys is as simple and easy to do!
    "
    pages={[
      "A charming home screen with job search CTA",
      "User onboarding flow with job search mockup",
      "Job listing example with job details and core data points",
      "Company profile screen with business data mockup",
    ]}
    projectCode="oaxKvoq3jBTQXXK3yzl5"
    img1={data.image1.childImageSharp}
    img1alt="job app design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="job app design template job search screen"
    img3={data.image3.childImageSharp}
    img3alt="job app design template results page screen"
    img4={data.image4.childImageSharp}
    img4alt="job app design template job onboarding screen"
    img5={data.image5.childImageSharp}
    img5alt="job app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/job-tablet-app/job-finding-app-tablet-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/job-tablet-app/job-finding-app-tablet-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/job-tablet-app/job-finding-app-tablet-results.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/job-tablet-app/job-finding-app-tablet-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/job-tablet-app/job-finding-app-tablet-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
